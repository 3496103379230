import gql from "graphql-tag";

export const LogFragmentGql = gql`
  fragment Log on Log {
    id
    user
    type
    timestamp
    status
    data
  }
`;
