import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { PropsWithChildren } from "react";
import { useQuery } from "@apollo/client";
import { Loader } from "semantic-ui-react";

import {
  Access,
  EnvName,
  GetInitDataQuery,
  GetInitDataQueryVariables,
  MarketFragment,
  UserFragment,
  WebAdminSystemInformationFragment,
} from "src/codegen-types";
import { GetInitDataGql } from "src/GraphQL/Queries/InitQueries";
import { useIsDarkModeDefault } from "src/Hooks/IsDarkModeDefaultHook";

import { ApolloErrorContext } from "./ApolloErrorContext";

type Modify<T, R> = Omit<T, keyof R> & R;

export type Market = {
  key: string;
  text: string;
  value: string;
} & MarketFragment;

export type WebAdminSystemInformation = Modify<
  WebAdminSystemInformationFragment,
  { markets: Market[] }
>;

export interface IAppContext {
  isDarkMode: boolean;
  isSignedIn: boolean;
  pageTitle: string;
  settings: WebAdminSystemInformation;
  user: UserFragment;
  setIsDarkMode: Dispatch<SetStateAction<boolean>>;
  setPageTitle: (newTitle: string) => void;
}

export const AppContext = createContext<IAppContext | undefined>(undefined);

export const AppContextProvider: React.FunctionComponent<PropsWithChildren> = (
  props,
) => {
  const isDarkModeDefault = useIsDarkModeDefault();
  const [networkRequestDone, setNetworkRequestDone] = useState(false);
  const [pageTitle, setPageTitle] = useState("ODL Admin");
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(isDarkModeDefault);
  const [user, setUser] = useState<UserFragment>();
  const [settings, setSettings] = useState<WebAdminSystemInformation>();

  const apolloErrorContext = useContext(ApolloErrorContext);

  const updatePageTitle = useCallback((newTitle: string) => {
    setPageTitle(newTitle);
  }, []);

  const networkRequestCompleted = useCallback(
    (data: GetInitDataQuery) => {
      setIsSignedIn(data.user !== undefined);
      setUser(data.user);
      setSettings({
        ...data.systemInformation,
        markets: data.systemInformation.markets?.map(
          (m) =>
            ({
              ...m,
              key: m.marketCode,
              text: m.fullName,
              value: m.marketCode,
            }) as Market,
        ),
      });

      if (!networkRequestDone) {
        setNetworkRequestDone(true);
      }
    },
    [networkRequestDone],
  );

  const networkRequestError = useCallback(() => {
    const timeout = setTimeout(() => {
      setUser(undefined);
      setIsSignedIn(false);

      if (!networkRequestDone) {
        setNetworkRequestDone(true);
      }
    }, 800);

    return () => clearTimeout(timeout);
  }, [networkRequestDone]);

  useQuery<GetInitDataQuery, GetInitDataQueryVariables>(GetInitDataGql, {
    fetchPolicy: "network-only",
    onCompleted: networkRequestCompleted,
    onError: networkRequestError,
    pollInterval: 10000,
    skip: apolloErrorContext?.accessDenied,
  });

  useEffect(() => {
    document
      .querySelector("body")
      ?.setAttribute(
        "style",
        `background-color: ${
          isDarkMode ? "#1b1c1d !important" : "#fff !important"
        }`,
      );

    window.localStorage.setItem("isDarkMode", isDarkMode.toString());
  }, [isDarkMode]);

  useEffect(() => {
    if (isSignedIn && apolloErrorContext?.accessDenied) {
      setIsSignedIn(false);
    }
  }, [apolloErrorContext?.accessDenied, isSignedIn]);

  return !networkRequestDone ? (
    <Loader active inverted={isDarkMode} />
  ) : (
    <AppContext.Provider
      value={{
        pageTitle: pageTitle,
        isSignedIn: isSignedIn,
        isDarkMode: isDarkMode,
        setPageTitle: updatePageTitle,
        setIsDarkMode: setIsDarkMode,
        user: user ?? {
          __typename: "User",
          id: "",
          displayName: "",
          access: Access.NONE,
        },
        settings: settings ?? {
          __typename: "WebAdminSystemInformation",
          environment: EnvName.DEVELOPMENT,
          markets: [],
        },
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
