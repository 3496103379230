import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateOnly: { input: string; output: string; }
  DateTimeOffset: { input: string; output: string; }
  Long: { input: number; output: number; }
};

export enum Access {
  ADMIN = 'ADMIN',
  ANY = 'ANY',
  NONE = 'NONE',
  SUPPORT = 'SUPPORT'
}

export enum EntityDataType {
  ASSET = 'ASSET',
  ATTRIBUTE = 'ATTRIBUTE',
  BARCODE = 'BARCODE',
  BRAND = 'BRAND',
  CAMPAIGN = 'CAMPAIGN',
  CATEGORY = 'CATEGORY',
  CONTACT = 'CONTACT',
  COUPON = 'COUPON',
  CUSTOMER = 'CUSTOMER',
  CVL = 'CVL',
  DELIVERY_METHOD = 'DELIVERY_METHOD',
  INVOICE = 'INVOICE',
  ITEM = 'ITEM',
  MEMO = 'MEMO',
  MIX_AND_MATCH = 'MIX_AND_MATCH',
  ORDER_EXTRA = 'ORDER_EXTRA',
  ORDER_REFERENCE = 'ORDER_REFERENCE',
  PAYMENT_TERMS_AND_METHOD = 'PAYMENT_TERMS_AND_METHOD',
  PRICELIST_HEAD = 'PRICELIST_HEAD',
  PRICELIST_ROW = 'PRICELIST_ROW',
  PRODUCT = 'PRODUCT',
  ROLE = 'ROLE',
  SELLER = 'SELLER',
  STOCK = 'STOCK',
  STORE = 'STORE',
  STORE_STAFF = 'STORE_STAFF',
  TRANSACTION = 'TRANSACTION',
  USER_M_3 = 'USER_M_3',
  VAT = 'VAT',
  WAREHOUSE = 'WAREHOUSE'
}

export enum EnvName {
  DEVELOPMENT = 'DEVELOPMENT',
  PRODUCTION = 'PRODUCTION',
  STAGING = 'STAGING',
  TEST = 'TEST'
}

export type Log = {
  __typename: 'Log';
  data?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** Status of the log. Used mainly to check progress of background tasks */
  status?: Maybe<WebAdminLogStatus>;
  timestamp: Scalars['DateTimeOffset']['output'];
  /** Type of this log document. E.g. which feature in ODL Web Admin was used */
  type: WebAdminLogType;
  user: Scalars['String']['output'];
};

export type Market = {
  __typename: 'Market';
  fullName: Scalars['String']['output'];
  marketCode: Scalars['String']['output'];
};

export enum NotificationCategory {
  PRICE = 'PRICE',
  PRICE_LIST_HEAD = 'PRICE_LIST_HEAD',
  PRICE_LIST_ROW = 'PRICE_LIST_ROW',
  SPARE_PART = 'SPARE_PART',
  STOCK = 'STOCK',
  UPCOMING_PRICELIST = 'UPCOMING_PRICELIST'
}

export type NotificationInput = {
  entityDataType: EntityDataType;
  identifiers: Array<Scalars['String']['input']>;
  markets?: InputMaybe<Array<Scalars['String']['input']>>;
  notificationCategories?: InputMaybe<Array<InputMaybe<NotificationCategory>>>;
  pricelistId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateOnly']['input']>;
};

export type NotificationMutation = {
  __typename: 'NotificationMutation';
  /** Send notifications to ODL consumers without the need to change source data */
  create: Scalars['Boolean']['output'];
};


export type NotificationMutationCreateArgs = {
  notification: NotificationInput;
};

export type PaginatedResultGraphType_2 = {
  __typename: 'PaginatedResultGraphType_2';
  hasNextPage: Scalars['Boolean']['output'];
  results: Array<Log>;
  totalCount: Scalars['Long']['output'];
};

export type RefreshSearchIndexMutation = {
  __typename: 'RefreshSearchIndexMutation';
  /** Abort a refresh search index task */
  abort: Scalars['Boolean']['output'];
  /** Create background job to reindex data in search index */
  create: Scalars['Boolean']['output'];
};


export type RefreshSearchIndexMutationAbortArgs = {
  logId: Scalars['String']['input'];
};


export type RefreshSearchIndexMutationCreateArgs = {
  searchIndexRefreshInput: SearchIndexRefreshInput;
};

export type RootMutation = {
  __typename: 'RootMutation';
  /** Find mutations here to manage notification tasks */
  notification: NotificationMutation;
  /** Find mutations here to manage refresh search index tasks */
  refreshSearchIndex: RefreshSearchIndexMutation;
  /** Find mutations here to manage store tasks */
  store: StoreMutation;
};

export type RootQuery = {
  __typename: 'RootQuery';
  /** Get a single log by id. */
  log?: Maybe<Log>;
  /** Paginate through all available logs. Logs are kept for 365 days. */
  logs: PaginatedResultGraphType_2;
  /** Get system and user settings. */
  systemInformation: WebAdminSystemInformation;
  /** The current user. */
  user: User;
};


export type RootQueryLogArgs = {
  id: Scalars['String']['input'];
};


export type RootQueryLogsArgs = {
  logTypeFilter?: InputMaybe<WebAdminLogType>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  skip: Scalars['Int']['input'];
  take: Scalars['Int']['input'];
};

export enum SearchIndexCategory {
  CONTACT = 'CONTACT',
  CUSTOMER = 'CUSTOMER',
  PRODUCT = 'PRODUCT'
}

export type SearchIndexRefreshInput = {
  category: SearchIndexCategory;
  /**
   * When ids are null all objects of selected
   * category will be indexed (only allowed for admins)
   */
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type StoreInput = {
  displayFrom: Scalars['DateOnly']['input'];
  marketCode: Scalars['String']['input'];
  openingDate: Scalars['DateOnly']['input'];
  storeId: Scalars['String']['input'];
  storeName: Scalars['String']['input'];
};

export type StoreMutation = {
  __typename: 'StoreMutation';
  /** Create a new store to ODL without the need to first add it in the source data */
  create: Scalars['Boolean']['output'];
};


export type StoreMutationCreateArgs = {
  storeInput: StoreInput;
};

export type User = {
  __typename: 'User';
  access: Access;
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export enum WebAdminLogStatus {
  ABORTED = 'ABORTED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  NEW = 'NEW'
}

export enum WebAdminLogType {
  NOTIFICATION = 'NOTIFICATION',
  SEARCH_INDEX_REFRESH = 'SEARCH_INDEX_REFRESH',
  STORE = 'STORE'
}

export type WebAdminSystemInformation = {
  __typename: 'WebAdminSystemInformation';
  clearWebAdminLogsOlderThanDays?: Maybe<Scalars['Int']['output']>;
  context: Scalars['String']['output'];
  environment: EnvName;
  markets: Array<Market>;
};

export type LogKeySpecifier = ('data' | 'id' | 'status' | 'timestamp' | 'type' | 'user' | LogKeySpecifier)[];
export type LogFieldPolicy = {
	data?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	timestamp?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MarketKeySpecifier = ('fullName' | 'marketCode' | MarketKeySpecifier)[];
export type MarketFieldPolicy = {
	fullName?: FieldPolicy<any> | FieldReadFunction<any>,
	marketCode?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NotificationMutationKeySpecifier = ('create' | NotificationMutationKeySpecifier)[];
export type NotificationMutationFieldPolicy = {
	create?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PaginatedResultGraphType_2KeySpecifier = ('hasNextPage' | 'results' | 'totalCount' | PaginatedResultGraphType_2KeySpecifier)[];
export type PaginatedResultGraphType_2FieldPolicy = {
	hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>,
	results?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RefreshSearchIndexMutationKeySpecifier = ('abort' | 'create' | RefreshSearchIndexMutationKeySpecifier)[];
export type RefreshSearchIndexMutationFieldPolicy = {
	abort?: FieldPolicy<any> | FieldReadFunction<any>,
	create?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RootMutationKeySpecifier = ('notification' | 'refreshSearchIndex' | 'store' | RootMutationKeySpecifier)[];
export type RootMutationFieldPolicy = {
	notification?: FieldPolicy<any> | FieldReadFunction<any>,
	refreshSearchIndex?: FieldPolicy<any> | FieldReadFunction<any>,
	store?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RootQueryKeySpecifier = ('log' | 'logs' | 'systemInformation' | 'user' | RootQueryKeySpecifier)[];
export type RootQueryFieldPolicy = {
	log?: FieldPolicy<any> | FieldReadFunction<any>,
	logs?: FieldPolicy<any> | FieldReadFunction<any>,
	systemInformation?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StoreMutationKeySpecifier = ('create' | StoreMutationKeySpecifier)[];
export type StoreMutationFieldPolicy = {
	create?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserKeySpecifier = ('access' | 'displayName' | 'id' | UserKeySpecifier)[];
export type UserFieldPolicy = {
	access?: FieldPolicy<any> | FieldReadFunction<any>,
	displayName?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>
};
export type WebAdminSystemInformationKeySpecifier = ('clearWebAdminLogsOlderThanDays' | 'context' | 'environment' | 'markets' | WebAdminSystemInformationKeySpecifier)[];
export type WebAdminSystemInformationFieldPolicy = {
	clearWebAdminLogsOlderThanDays?: FieldPolicy<any> | FieldReadFunction<any>,
	context?: FieldPolicy<any> | FieldReadFunction<any>,
	environment?: FieldPolicy<any> | FieldReadFunction<any>,
	markets?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StrictTypedTypePolicies = {
	Log?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LogKeySpecifier | (() => undefined | LogKeySpecifier),
		fields?: LogFieldPolicy,
	},
	Market?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MarketKeySpecifier | (() => undefined | MarketKeySpecifier),
		fields?: MarketFieldPolicy,
	},
	NotificationMutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NotificationMutationKeySpecifier | (() => undefined | NotificationMutationKeySpecifier),
		fields?: NotificationMutationFieldPolicy,
	},
	PaginatedResultGraphType_2?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedResultGraphType_2KeySpecifier | (() => undefined | PaginatedResultGraphType_2KeySpecifier),
		fields?: PaginatedResultGraphType_2FieldPolicy,
	},
	RefreshSearchIndexMutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RefreshSearchIndexMutationKeySpecifier | (() => undefined | RefreshSearchIndexMutationKeySpecifier),
		fields?: RefreshSearchIndexMutationFieldPolicy,
	},
	RootMutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RootMutationKeySpecifier | (() => undefined | RootMutationKeySpecifier),
		fields?: RootMutationFieldPolicy,
	},
	RootQuery?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RootQueryKeySpecifier | (() => undefined | RootQueryKeySpecifier),
		fields?: RootQueryFieldPolicy,
	},
	StoreMutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | StoreMutationKeySpecifier | (() => undefined | StoreMutationKeySpecifier),
		fields?: StoreMutationFieldPolicy,
	},
	User?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserKeySpecifier | (() => undefined | UserKeySpecifier),
		fields?: UserFieldPolicy,
	},
	WebAdminSystemInformation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | WebAdminSystemInformationKeySpecifier | (() => undefined | WebAdminSystemInformationKeySpecifier),
		fields?: WebAdminSystemInformationFieldPolicy,
	}
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;
export type LogFragment = { __typename: 'Log', id: string, user: string, type: WebAdminLogType, timestamp: string, status?: WebAdminLogStatus, data?: string };

export type MarketFragment = { __typename: 'Market', marketCode: string, fullName: string };

export type UserFragment = { __typename: 'User', id: string, displayName: string, access: Access };

export type WebAdminSystemInformationFragment = { __typename: 'WebAdminSystemInformation', environment: EnvName, clearWebAdminLogsOlderThanDays?: number, markets: Array<{ __typename: 'Market', marketCode: string, fullName: string }> };

export type CreateRefreshSearchIndexTaskMutationVariables = Exact<{
  refreshInput: SearchIndexRefreshInput;
}>;


export type CreateRefreshSearchIndexTaskMutation = { __typename: 'RootMutation', refreshSearchIndex: { __typename: 'RefreshSearchIndexMutation', create: boolean } };

export type CreateNotificationMutationVariables = Exact<{
  notification: NotificationInput;
}>;


export type CreateNotificationMutation = { __typename: 'RootMutation', notification: { __typename: 'NotificationMutation', create: boolean } };

export type CreateStoreMutationVariables = Exact<{
  storeInput: StoreInput;
}>;


export type CreateStoreMutation = { __typename: 'RootMutation', store: { __typename: 'StoreMutation', create: boolean } };

export type GetInitDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInitDataQuery = { __typename: 'RootQuery', user: { __typename: 'User', id: string, displayName: string, access: Access }, systemInformation: { __typename: 'WebAdminSystemInformation', environment: EnvName, clearWebAdminLogsOlderThanDays?: number, markets: Array<{ __typename: 'Market', marketCode: string, fullName: string }> } };

export type LogsQueryVariables = Exact<{
  skip: Scalars['Int']['input'];
  take: Scalars['Int']['input'];
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  logTypeFilter?: InputMaybe<WebAdminLogType>;
}>;


export type LogsQuery = { __typename: 'RootQuery', logs: { __typename: 'PaginatedResultGraphType_2', hasNextPage: boolean, totalCount: number, results: Array<{ __typename: 'Log', id: string, user: string, type: WebAdminLogType, timestamp: string, status?: WebAdminLogStatus, data?: string }> } };

export type LogQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type LogQuery = { __typename: 'RootQuery', log?: { __typename: 'Log', id: string, user: string, type: WebAdminLogType, timestamp: string, status?: WebAdminLogStatus, data?: string } };

export const namedOperations = {
  Query: {
    GetInitData: 'GetInitData',
    Logs: 'Logs',
    Log: 'Log'
  },
  Mutation: {
    CreateRefreshSearchIndexTask: 'CreateRefreshSearchIndexTask',
    CreateNotification: 'CreateNotification',
    CreateStore: 'CreateStore'
  },
  Fragment: {
    Log: 'Log',
    Market: 'Market',
    User: 'User',
    WebAdminSystemInformation: 'WebAdminSystemInformation'
  }
}