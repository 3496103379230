import { useContext, useState } from "react";
import { QueryResult } from "@apollo/client";
import dayjs from "dayjs";
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";

import { Log, LogsQuery, LogsQueryVariables } from "src/codegen-types";
import { AppContext } from "src/Contexts/AppContext";

import LogTableStatus from "./LogTableStatusComponent";

interface LogTableComponentProps {
  logsQueryResult: QueryResult<LogsQuery, LogsQueryVariables>;
  showFetchMore?: boolean;
}

const LogTable: React.FunctionComponent<LogTableComponentProps> = (props) => {
  const appContext = useContext(AppContext);

  const [selectedLog, setSelectedLog] = useState<Log>();

  return (
    <>
      <Table inverted={appContext?.isDarkMode} striped>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>Type</TableHeaderCell>
            <TableHeaderCell>User</TableHeaderCell>
            <TableHeaderCell textAlign="center">Status</TableHeaderCell>
            <TableHeaderCell textAlign="center">Data</TableHeaderCell>
            <TableHeaderCell>Timestamp</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {props.logsQueryResult.data?.logs.results.map((log) => (
            <TableRow key={`TableRow${log.id}`}>
              <TableCell>{log.type}</TableCell>
              <TableCell>{log.user}</TableCell>
              <TableCell textAlign="center" verticalAlign="middle">
                <LogTableStatus
                  status={log.status}
                  progress={JSON.parse(log.data ?? "").Progress}
                />
              </TableCell>
              <TableCell textAlign="center">
                {log.data && (
                  <Button primary onClick={() => setSelectedLog(log)}>
                    View
                  </Button>
                )}
              </TableCell>
              <TableCell>{dayjs(log.timestamp).format("L LTS")}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {props.showFetchMore && props.logsQueryResult.data?.logs.hasNextPage && (
        <Button
          className="formActionGroup"
          onClick={() => {
            props.logsQueryResult.fetchMore({
              variables: {
                skip: props.logsQueryResult.data?.logs.results.length,
              },
              updateQuery(previousQueryResult, { fetchMoreResult }) {
                return {
                  __typename: "RootQuery",
                  logs: {
                    ...fetchMoreResult.logs,
                    results: [
                      ...previousQueryResult.logs.results,
                      ...fetchMoreResult.logs.results,
                    ],
                  },
                };
              },
            });
          }}
        >
          Fetch more
        </Button>
      )}

      {selectedLog && selectedLog.data && (
        <Modal dimmer="blurring" open>
          <ModalHeader>
            Data for "{selectedLog.type}" log at{" "}
            {dayjs(selectedLog.timestamp).format("L LTS")}
          </ModalHeader>
          <ModalContent>
            <pre>{JSON.stringify(JSON.parse(selectedLog.data), null, 2)}</pre>
          </ModalContent>
          <ModalActions>
            <Button
              primary
              onClick={() => {
                setSelectedLog(undefined);
              }}
            >
              Close
            </Button>
          </ModalActions>
        </Modal>
      )}
    </>
  );
};

export default LogTable;
