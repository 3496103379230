import gql from "graphql-tag";

import { MarketFragmentGql } from "./MarketFragments";

export const WebAdminSystemInformationFragmentGql = gql`
  fragment WebAdminSystemInformation on WebAdminSystemInformation {
    environment
    clearWebAdminLogsOlderThanDays
    markets {
      ...Market
    }
  }
  ${MarketFragmentGql}
`;
