import gql from "graphql-tag";

import { UserFragmentGql } from "../Fragments/UserFragments";
import { WebAdminSystemInformationFragmentGql } from "../Fragments/WebAdminSystemInformationFragments";

export const GetInitDataGql = gql`
  query GetInitData {
    user {
      ...User
    }
    systemInformation {
      ...WebAdminSystemInformation
    }
  }
  ${UserFragmentGql}
  ${WebAdminSystemInformationFragmentGql}
`;
