import { SearchIndexCategory } from "src/codegen-types";
import { getAllKeysOfEnum } from "src/Helpers/EnumHelper";

export const header = {
  title: "Refresh Search Index",
  subTitle: "Re-index data in ODLs Azure Search indexes",
  informationMessage: (
    <>
      <p>
        If data in ODLs search indexes has been stale because of any reason you
        can use this feature to re-index posts with the latest data from ODL
        database
      </p>
      <p>
        For admins only: You can leave identifiers blank to re-index all posts
        from the database of the selected category
      </p>
    </>
  ),
};

export const multipleIdsInputRegex = new RegExp(/^[\w\s,]*$/);

export const indexCategories = getAllKeysOfEnum(SearchIndexCategory).map(
  (key) => ({
    text: key,
    value: key,
  }),
);
