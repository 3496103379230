import { useCallback, useState } from "react";

export const useToggle = (defaultValue: boolean): [boolean, () => void] => {
  const [currentValue, setCurrentValue] = useState(defaultValue);

  const toggle = useCallback(() => {
    setCurrentValue(!currentValue);
  }, [currentValue]);

  return [currentValue, toggle];
};
