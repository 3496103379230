import { useContext, useEffect } from "react";

import { AppContext } from "src/Contexts/AppContext";

export const useDocumentTitle = (documentTitle: string) => {
  const appContext = useContext(AppContext);

  useEffect(() => {
    appContext?.setPageTitle(documentTitle);
    document.title = `ODL Admin - ${documentTitle}`;
  }, [appContext, documentTitle]);
};
