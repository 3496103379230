import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import "dayjs/locale/sv";

import { ApolloErrorContextProvider } from "./Contexts/ApolloErrorContext";
import { AppContextProvider } from "./Contexts/AppContext";
import { ODLAdminApolloProvider } from "./Providers/ODLAdminApolloProvider";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.scss";
import "semantic-ui-css/semantic.min.css";

dayjs.extend(localizedFormat);
dayjs.locale("sv");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <ApolloErrorContextProvider>
    <ODLAdminApolloProvider>
      <AppContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AppContextProvider>
    </ODLAdminApolloProvider>
  </ApolloErrorContextProvider>,
);

reportWebVitals(console.log);
