import { useContext } from "react";
import {
  Button,
  Icon,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "semantic-ui-react";

import { AppContext } from "src/Contexts/AppContext";
import { useBackendUrl } from "src/Hooks/BackendUrlHook";

const UserLoginModal: React.FunctionComponent = () => {
  const backendUrl = useBackendUrl();
  const appContext = useContext(AppContext);

  return (
    <Modal
      dimmer="blurring"
      open={!appContext?.isSignedIn}
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      closeOnPortalMouseLeave={false}
    >
      <ModalHeader>
        <Icon name="warning sign" />
        You are not signed in
      </ModalHeader>
      <ModalContent>
        <p>
          You are not signed in or you are not authorized to use ODL Web Admin.
          Press button below to sign in again.
        </p>
      </ModalContent>
      <ModalActions>
        <Button
          primary
          icon
          labelPosition="right"
          onClick={() => {
            window.location.href = `${backendUrl}/api/account/login/?returnUrl=${window.location.pathname}`;
          }}
        >
          Log in <Icon name="sign-in" />
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default UserLoginModal;
