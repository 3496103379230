import { ReactNode, useMemo } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Icon,
  Message,
  SemanticCOLORS,
  SemanticICONS,
} from "semantic-ui-react";

import { useToggle } from "src/Hooks/ToggleHook";

import "./InformationPanelComponent.scss";

export enum InformationPanelType {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
}

interface InformationPanelProps {
  title: string;
  message: string | ReactNode;
  type: InformationPanelType;
  collapsable?: boolean;
}

const InformationPanel: React.FunctionComponent<InformationPanelProps> = (
  props,
) => {
  const [accordionIsActive, toggleAccordionIsActive] = useToggle(false);

  const { panelColor, panelIcon } = useMemo((): {
    panelColor: SemanticCOLORS;
    panelIcon: SemanticICONS;
  } => {
    switch (props.type) {
      case InformationPanelType.INFO:
        return { panelColor: "blue", panelIcon: "info circle" };
      case InformationPanelType.SUCCESS:
        return { panelColor: "green", panelIcon: "check circle" };
      case InformationPanelType.WARNING:
        return { panelColor: "yellow", panelIcon: "info circle" };
      case InformationPanelType.ERROR:
        return { panelColor: "red", panelIcon: "warning sign" };
      default:
        return { panelColor: "blue", panelIcon: "info circle" };
    }
  }, [props.type]);

  return (
    <Message color={panelColor} className="informationPanel">
      {props.collapsable ? (
        <Accordion>
          <AccordionTitle
            active={accordionIsActive}
            onClick={toggleAccordionIsActive}
          >
            <Icon name="dropdown" />
            <Icon
              name={panelIcon as SemanticICONS}
              color={panelColor as SemanticCOLORS}
            />
            <strong>{props.title}</strong>
          </AccordionTitle>
          <AccordionContent active={accordionIsActive}>
            {props.message}
          </AccordionContent>
        </Accordion>
      ) : (
        <>
          <Icon
            name={panelIcon as SemanticICONS}
            color={panelColor as SemanticCOLORS}
          />
          <strong>{props.title}</strong>
          {typeof props.message === "string" ? (
            <p>{props.message}</p>
          ) : (
            props.message
          )}
        </>
      )}
    </Message>
  );
};

export default InformationPanel;
