import gql from "graphql-tag";

export const CreateRefreshSearchIndexTaskGql = gql`
  mutation CreateRefreshSearchIndexTask(
    $refreshInput: SearchIndexRefreshInput!
  ) {
    refreshSearchIndex {
      create(searchIndexRefreshInput: $refreshInput)
    }
  }
`;
