import { Icon, Progress } from "semantic-ui-react";

import { WebAdminLogStatus } from "src/codegen-types";

import "./LogTableStatusComponent.scss";

interface LogStatusComponentProps {
  status?: WebAdminLogStatus;
  progress?: string | number;
}

const rootClassName = "log-table-status";

const LogTableStatus: React.FunctionComponent<LogStatusComponentProps> = (
  props,
) => {
  return (
    <span className={rootClassName}>
      {props.status === WebAdminLogStatus.COMPLETED && (
        <Icon name="check" size="large" color="green" />
      )}
      {props.status === WebAdminLogStatus.ABORTED && (
        <>
          <Icon name="ban" size="large" color="orange" />
          <br />
          <span className={`${rootClassName}__aborted`}>ABORTED</span>
        </>
      )}
      {props.status === WebAdminLogStatus.FAILED && (
        <Icon name="exclamation triangle" size="large" color="red" />
      )}
      {props.status === WebAdminLogStatus.NEW && (
        <Icon loading name="spinner" size="large" color="grey" />
      )}
      {props.status === WebAdminLogStatus.IN_PROGRESS && (
        <Progress
          className={`${rootClassName}__progress`}
          percent={props.progress}
          indicating
          size="small"
          label={
            <p className={`${rootClassName}__progress-label`}>
              {(typeof props.progress === "number"
                ? Math.floor(props.progress)
                : props.progress) + "%"}
            </p>
          }
        />
      )}
    </span>
  );
};

export default LogTableStatus;
