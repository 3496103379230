import gql from "graphql-tag";

import { LogFragmentGql } from "../Fragments/LogFragments";

export const GetLogsGql = gql`
  query Logs(
    $skip: Int!
    $take: Int!
    $searchQuery: String
    $logTypeFilter: WebAdminLogType
  ) {
    logs(
      skip: $skip
      take: $take
      searchQuery: $searchQuery
      logTypeFilter: $logTypeFilter
    ) {
      hasNextPage
      totalCount
      results {
        ...Log
      }
    }
  }
  ${LogFragmentGql}
`;

export const GetLogGql = gql`
  query Log($id: String!) {
    log(id: $id) {
      ...Log
    }
  }
  ${LogFragmentGql}
`;
