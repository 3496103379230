import { createContext, useState } from "react";
import { PropsWithChildren } from "react";

export interface IApolloErrorContext {
  accessDenied: boolean;
  setAccessDenied: (accessDenied: boolean) => void;
}

export const ApolloErrorContext = createContext<
  IApolloErrorContext | undefined
>(undefined);

export const ApolloErrorContextProvider: React.FunctionComponent<
  PropsWithChildren
> = (props) => {
  const [accessDenied, setAccessDenied] = useState(false);
  return (
    <ApolloErrorContext.Provider
      value={{
        accessDenied: accessDenied,
        setAccessDenied: setAccessDenied,
      }}
    >
      {props.children}
    </ApolloErrorContext.Provider>
  );
};
