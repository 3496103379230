import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Loader } from "semantic-ui-react";

import RefreshSearchIndexPage from "./Pages/RefreshSearchIndex/RefreshSearchIndexPage";
import MainLayout from "./UI/Components/MainLayout/MainLayoutComponent";
import UserLoginModal from "./UI/Components/UserLoginModal/UserLoginModalComponent";
import { urls } from "./globals.constants";

const StartPage = React.lazy(() => import("./Pages/Start/StartPage"));
const SendNotificationsPage = React.lazy(
  () => import("./Pages/SendNotifications/SendNotificationsPage"),
);
const AddStorePage = React.lazy(() => import("./Pages/AddStore/AddStorePage"));
const HistoryPage = React.lazy(() => import("./Pages/History/HistoryPage"));

export const App: React.FunctionComponent = () => {
  return (
    <>
      <UserLoginModal />
      <MainLayout>
        <Suspense fallback={<Loader active />}>
          <Routes>
            <Route path="/" element={<StartPage />} />
            <Route
              path={urls.sendNotificationsPage}
              element={<SendNotificationsPage />}
            />
            <Route path={urls.addStorePage} element={<AddStorePage />} />
            <Route path={urls.historyPage} element={<HistoryPage />} />
            <Route
              path={urls.refreshSearchIndexPage}
              element={<RefreshSearchIndexPage />}
            />
            <Route path="*" element={<div>404: Page not found</div>} />
          </Routes>
        </Suspense>
      </MainLayout>
    </>
  );
};

export default App;
