import { useCallback, useContext } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import {
  Form,
  FormButton,
  FormGroup,
  FormInput,
  FormSelect,
} from "semantic-ui-react";

import {
  CreateRefreshSearchIndexTaskMutation,
  CreateRefreshSearchIndexTaskMutationVariables,
  SearchIndexCategory,
  SearchIndexRefreshInput,
  WebAdminLogType,
} from "src/codegen-types";
import { AppContext } from "src/Contexts/AppContext";
import { CreateRefreshSearchIndexTaskGql } from "src/GraphQL/Mutations/RefreshSearchIndexMutations";
import { useDocumentTitle } from "src/Hooks/DocumentTitleHook";
import Page from "src/UI/Components/PageContainer/PageContainerComponent";

import {
  header,
  indexCategories,
  multipleIdsInputRegex,
} from "./RefreshSearchIndexPage.Constants";

const RefreshSearchIndexPage: React.FunctionComponent = () => {
  useDocumentTitle("Refresh Search Index");

  const appContext = useContext(AppContext);

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors: formErrors },
  } = useForm<SearchIndexRefreshInput & { identifiersString: string }>({
    defaultValues: { category: undefined, identifiersString: "" },
  });

  const [refreshIndex, refreshIndexRequest] = useMutation<
    CreateRefreshSearchIndexTaskMutation,
    CreateRefreshSearchIndexTaskMutationVariables
  >(CreateRefreshSearchIndexTaskGql, {
    onCompleted: () => {
      reset();
    },
  });

  const onSubmit: SubmitHandler<
    SearchIndexRefreshInput & { identifiersString: string }
  > = useCallback(
    async (formFields) => {
      const { identifiersString, ...formData } = {
        ...formFields,
        ids:
          formFields.identifiersString
            ?.replaceAll(",", " ")
            .trim()
            .split(/\s+/) ?? [],
      };

      await refreshIndex({
        variables: {
          refreshInput: formData,
        },
      });
    },
    [refreshIndex],
  );

  return (
    <Page
      headerIcon="refresh"
      headerTitle={header.title}
      headerSubtitle={header.subTitle}
      informationMessage={header.informationMessage}
      showLogForType={WebAdminLogType.SEARCH_INDEX_REFRESH}
      errorMessage={refreshIndexRequest.error}
      loading={refreshIndexRequest.loading}
      successMessage={
        !refreshIndexRequest.loading &&
        refreshIndexRequest.data?.refreshSearchIndex.create === true
          ? "Data successfully sent for re indexing."
          : undefined
      }
    >
      <Form inverted={appContext?.isDarkMode} onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormSelect
            width={3}
            label="Index Categories"
            placeholder="Select"
            fluid
            selection
            search
            options={indexCategories}
            {...register("category", {
              validate: (value) => (!value ? "Must have a value" : undefined),
            })}
            onChange={(_, data) => {
              setValue("category", data.value as SearchIndexCategory);
            }}
          />
          <FormInput
            width={13}
            key="identifiers"
            label="Identifiers"
            placeholder="004477"
            type="text"
            {...register("identifiersString", {
              pattern: {
                value: multipleIdsInputRegex,
                message: "Please enter a list of space or comma separated Ids",
              },
            })}
            error={
              formErrors.identifiersString
                ? formErrors.identifiersString.message
                : false
            }
          />
        </FormGroup>
        <FormButton primary>Refresh Search Index</FormButton>
      </Form>
    </Page>
  );
};

export default RefreshSearchIndexPage;
