import { PropsWithChildren, useContext, useMemo } from "react";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import fragmentMatcher from "src/codegen-fragmentmatcher";
import { StrictTypedTypePolicies } from "src/codegen-types";
import { ApolloErrorContext } from "src/Contexts/ApolloErrorContext";

export const ODLAdminApolloProvider: React.FunctionComponent<
  PropsWithChildren
> = (props) => {
  const apolloErrorContext = useContext(ApolloErrorContext);

  const { httpLink, errorLink, cache } = useMemo(() => {
    const typePolicies: StrictTypedTypePolicies = {};

    const httpLink = createHttpLink({
      uri: "/api/graphql",
    });

    const errorLink = onError(({ graphQLErrors }) => {
      if (
        !apolloErrorContext?.accessDenied &&
        graphQLErrors?.some(
          (error) => error.extensions["code"] === "ACCESS_DENIED",
        )
      ) {
        apolloErrorContext?.setAccessDenied(true);
      }
    });

    const cache = new InMemoryCache({
      possibleTypes: fragmentMatcher.possibleTypes,
      typePolicies: typePolicies,
    });

    return { httpLink, errorLink, cache };
  }, [apolloErrorContext]);

  const client = new ApolloClient({
    cache: cache,
    link: ApolloLink.from([errorLink, httpLink]),
    defaultOptions: {
      watchQuery: { fetchPolicy: "network-only" },
      mutate: { fetchPolicy: "network-only", errorPolicy: "all" },
    },
  });

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
